import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination.js';
import './Pagination.css'
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange?.[paginationRange?.length - 1];
  return (
    <>

      <section className="el-pagination d-flex justify-content-center align-items-center py-5 mt-5rem">
        <nav aria-label="Page navigation example">
          <ul className={"pagination align-items-center gap-md-1rem" + " " + (classnames('pagination-container', { [className]: className }))}>
            <li id="pagination-previous" className={"page-item pe-5" + " " + classnames('pagination-item', {
              disabled: currentPage === 1
            })}
              onClick={onPrevious} >
              <a className="pagination-icons mouse page-link round-border pgn-icon-height d-flex justify-content-center align-items-center" 
                onClick={(e)=>e.preventDefault()}>
                <span aria-hidden="true">
                  <img  src="/assets/left-arrow.svg" alt="" className="right-arrrow-pgn" />
                </span>
              </a>
            </li>
            {paginationRange?.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
              }
              return (
                <li key={pageNumber} className={"pagination-item dots page-item" + " " + classnames('pagination-item', {
                  selected: pageNumber === currentPage
                })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  <a className="page-link page-num mouse" onClick={(e)=>e.preventDefault()}>{pageNumber}</a>
                </li>
              )
            })
            }

            <li id="pagination-next" className={"page-item ps-5" + " " + classnames('pagination-item', {
              disabled: currentPage === lastPage
            })}
              onClick={onNext}>
              <a className="pagination-icons page-link mouse round-border d-flex justify-content-center align-items-center pgn-icon-height" 
                onClick={(e)=>e.preventDefault()}>
                <span aria-hidden="true">
                  <img  src="/assets/right-arrow.svg" alt="right arrow svg" className="right-arrrow-pgn" />
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </section>
    </>
  );
};

export default Pagination;
