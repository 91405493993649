import { useEffect, useRef, useState } from 'react';
import './Accordion.css';
export default function CustomAccordian(){
    const [active, setActive] = useState(0)
    const accordion = useRef(null);
    useEffect(() => {
        let buttons = accordion.current.querySelectorAll(":scope > div button.accordion-button")
        const interval = setInterval(() => {
            let totalSlides = 4;
            setActive(prevActive => {
                let val = (prevActive + 1) % totalSlides;
                buttons[val].click();
                return val;
            });
        }, 7000);
        
        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [active]); // Add active to the dependency array
    return(
        <>
        <div className='ft-wrapper d-flex w-100 align-items-start gap-2 justify-content-between mt-4'>
            <div className="accordion custom-accordion mt-3" ref={accordion} id="accordionExample">
                <div id='accordion-item' className={"accordion-item"+(active==0?' active':'')}>
                    <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" id='ca-heading' type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={()=>{setActive(0)}}>
                    Heart Rate Insights & Tracking
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Obtain a comprehensive visual analysis of your heart rate variations over the course of the day. Understand the implications of different heart rate zones on your overall health.
                    <img className="acc-img" ratio="640x639" lazysrc='assets/heart-rate-img.png' alt='alalyzing heart rate myfinesscoach'/>
                    </div>
                    </div>
                </div>
                <div id='accordion-item' className={"accordion-item"+(active==1?' active':'')}>
                    <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" id='ca-heading' type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={()=>{setActive(1)}}>
                    Sleep Insights
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Track your sleep routine to get a detailed sleep analysis and sleep score. Understand how your sleep habits contribute to your fitness journey.
                    <img className="acc-img" ratio="640x640" lazysrc='assets/sleep-insights-img.png' alt='track sleep routine'/>
                    </div>
                    </div>
                </div>
                <div id='accordion-item' className={"accordion-item"+(active==2?' active':'')}>
                    <h2 className="accordion-header" id="headingThree ca-heading">
                    <button className="accordion-button collapsed" id='ca-heading' type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={()=>{setActive(2)}}>
                    Daily Steps Taken
                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Monitor your daily steps and distance covered. See visual trends of your walking patterns and set new goals to achieve higher levels of fitness.
                    <img className="acc-img" ratio="640x583" lazysrc='assets/daily-steps-img.png' alt='monitor daily steps via myfitnesscoach app'/>
                    </div>
                    </div>
                </div>
                <div id='accordion-item' className={"accordion-item"+(active==3?' active':'')}>
                    <h2 className="accordion-header" id="headingFour ca-heading">
                    <button className="accordion-button collapsed" id='ca-heading' type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={()=>{setActive(3)}}>
                    Calories Burned
                    </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Track how your daily physical activities influence your calorie-burning rate with detailed graphs. Visualize the correlation between your exercise routines and the number of calories you burn each day.
                    <img className="acc-img" ratio="640x629" lazysrc='assets/calories-burned-img.png' alt='track calories burn via myfitnesscoach app'/>
                    </div>
                    </div>
                </div>
            </div>
            <div id='ftcItems' className='ft-carousel  mt-2'>
                <img className={'ftc-item'+(active==0?' active':'')} src='assets/heart-rate-img.png' alt='alalyzing heart rate myfinesscoach'/>
                <img className={'ftc-item'+(active==1?' active':'')} src='assets/sleep-insights-img.png' alt='track sleep routine'/>
                <img className={'ftc-item'+(active==2?' active':'')} src='assets/daily-steps-img.png' alt='monitor daily steps via myfitnesscoach app'/>
                <img className={'ftc-item'+(active==3?' active':'')} src='assets/calories-burned-img.png' alt='track calories burn via myfitnesscoach app'/>
            </div>
        </div>
        </>
    )
}


