import React from "react";
import './DelAccount.css';

export default function DelAccount() {
    return (
        <>
            <main className="px-responsive-5">
                <section id="delAccount" className="delete-account">
                    <div style={{ backgroundColor: '#37395D;' }}>
                        <div className="secondary-wrapper blogs-container">
                            <div className="container container-padding-fluid">
                                <h1 className="blogs-h2">
                                    <b>How to Delete Your MyFitnessCoach App Account</b>
                                </h1>
                                <p className="card-mid-p">
                                     In today's digital age, managing your online accounts is an essential part of maintaining your privacy and digital footprint. If you've decided to part ways with the MyFitnessCoach app, whether because you've found an alternative fitness solution or simply no longer need the service, deleting your account is a straightforward process. This guide will walk you through the steps to permanently delete your MyFitnessCoach app account.
                                </p>
                                <p className="blogs-h2">
                                    <b>Why Delete Your MyFitnessCoach Account?</b>
                                </p>
                                <p className="card-mid-p">
                                     Before diving into the steps, it's worth considering why you might want to delete your account. Some common reasons include:
                                </p>
                                <p className="card-mid-p">
                                    <b>Privacy concerns: </b> You may want to ensure your personal data is no longer stored on the app's servers.
                                </p>
                                <p className="card-mid-p">
                                    <b>Inactivity: </b> If you no longer use the app, deleting your account can help declutter your digital life.
                                </p>
                                <p className="card-mid-p">
                                    <b>Switching to another platform: </b> You might have found a different fitness app that better suits your needs.
                                </p>
                                <p className="card-mid-p">
                                    <b>Subscription management: </b> If you're paying for a premium subscription, deleting your account ensures you won't be charged further.
                                </p>
                                <p className="blogs-h2">
                                    <b>Steps to Delete Your MyFitnessCoach Account</b>
                                </p>
                                <p className="card-mid-p">Deleting your MyFitnessCoach account is a permanent action, so make sure you've backed up any data or progress you want to keep. Once your account is deleted, you won't be able to recover your information. Here's how to proceed:</p>
                                <p className="blogs-h3">
                                    1. Open the MyFitnessCoach App
                                </p>
                                <p className="card-mid-p">
                                Launch the MyFitnessCoach app on your smartphone or tablet. Log in to your account using your credentials if you aren't already logged in.
                                </p>
                                <p className="blogs-h3">
                                    2. Navigate to Account Settings
                                </p>
                                <p className="card-mid-p">
                                 Once you're logged in, look for the <b>Settings</b> section. This is typically found in the app's menu, which may be accessible by clicking the <b>hamburger icon</b> on the top left of your app home screen.
                                </p>
                                <p className="blogs-h3">
                                    3. Locate the Delete Account Option
                                </p>
                                <p className="card-mid-p">
                                In the Account Settings, search for an option labeled <b>Delete Account</b>. If you don't see this option immediately, check under <b>Privacy</b> or <b>Security</b> settings.
                                </p>
                                <p className="blogs-h3">
                                    4. Follow the On-Screen Instructions
                                </p>
                                <p className="card-mid-p">
                                MyFitnessCoach may ask you to confirm your decision or provide a reason for deleting your account.
                                </p>
                                <p className="blogs-h3">
                                    5. Check for Email Confirmation
                                </p>
                                <p className="card-mid-p">
                                After initiating the deletion process, you may receive an email from MyFitnessCoach asking you to confirm your request. Click the link in the email to finalize the deletion. If you don't see the email, you might get an instant notification on your app.
                                </p>
                                <p className="blogs-h3">
                                    6. Unsubscribe from Emails (Optional)
                                </p>
                                <p className="card-mid-p">
                                If you no longer wish to receive emails from MyFitnessCoach, you can unsubscribe separately. Look for an “Unsubscribe” link at the bottom of any email from the company.
                                </p>
                                <p className="blogs-h2">
                                    What Happens After Deleting Your Account?
                                </p>
                                <p className="card-mid-p">
                                    Once your account is deleted:
                                </p>
                                <ul className="blogs-list">
                                    <li>Your personal data, including workout history, progress, and preferences, will be removed from MyFitnessCoach's servers.</li>
                                    <li>You will lose access to any premium features or subscriptions tied to your account.</li>
                                    <li>You won't be able to recover your account or data after deletion.</li>
                                </ul>
                                <p className="blogs-h2">Contacting Customer Support</p>
                                <p className="card-mid-p">
                                If you encounter any issues or
                                can't find the delete account option, reach out to MyFitnessCoach's customer support team. They can guide you through the process or delete your account on your behalf. You can usually find their contact information on the app's website or in the Help section of the app. <br/> <br/>
                                Deleting your MyFitnessCoach account is a simple process that ensures your data is no longer stored on the platform. Whether you're moving on to a new fitness journey or simply taking a break, following these steps will help you manage your account responsibly. Always remember to back up any important data before proceeding with account deletion.<br/> <br/>
                                By taking control of your digital presence, you can ensure your online experience aligns with your current needs and preferences. Happy fitness journey, wherever it may take you next!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}