import React, { useEffect, useState } from 'react';
import './Articles.css';
import global_pointer from './Config';
import Pagination from "../components/utils/Pagination"
import { GlobalMethods } from '../services/GlobalMethods';
import SocialBar from './utils/SocialBar';
import ACarousel from './utils/AliceCarousel';
import { servicex } from '../services/Api';

// pagination items per page
let PageSize = 6;
// is component loaded for each category
var loaded_ = []
export default function Articles(props) {
  const service = new servicex()
  const methods = new GlobalMethods();
  const { category, isLoaded, setIsLoaded } = props
  const imgURL = global_pointer.imgURL;
  const [data, setData] = useState([]);
  const [recentArticle, setRecentArticle] = useState(null);
  const [trendingArticles, setTrendingArticles] = useState(null);
  const [total, setTotal] = useState(0)
  const [screen, setScreen] = useState(window.innerWidth)

  const getBlogs = () => {
    console.log("loaded!", category)
    service.getBlogsPage(category,1,6).then(x => {
      if (x?.data && Object.entries(x?.data)?.length) {
        setTotal(x.data.total)
        setData(x.data.blogs);
        setRecentArticle(x.data.recent)
        setTrendingArticles(x.data.trending)
      }
    }).catch(error => {
      console.error("custom-error:", error)
    })
  }

  if (!isLoaded?.includes(category)) {
    getBlogs()
    loaded_.push(category)
  }

  useEffect(() => {
    setIsLoaded(loaded_)
  }, [loaded_])

  useEffect(() => {
    console.log("once", category, isLoaded)
    window.addEventListener('resize', (e) => {
      setScreen(window.innerWidth)
    })
  }, [])

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([])

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    service.getBlogsByOffset(category, firstPageIndex+1, lastPageIndex).then(x => {
      setCurrentPageData(x.data.blogs)
    })
  }, [currentPage]);

  return (
    (!data?.length) ?
      <>
        <div className="spinner-grow text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </>
      :
      <>
        <div className="blogs-page-top d-flex pt-4 cg rg">
          <div className="recent-articles d-flex flex-column rg">
            <h2 className="blogs-article-head">Recent Article</h2>
            <div className='d-flex flex-column gap-3 recent-article-card overflow-hidden'>
              <div className="blogs-big-card d-flex flex-column rg">
                <img  className="big-card-img w-100"
                  src={imgURL + recentArticle?.imagepath} alt={recentArticle?.meta_keyword} />
                <span className="px-3 big-card-date">By: <b> {recentArticle?.author} &#183;
                  {methods.formatDate(recentArticle?.createdAt)}</b>
                </span>
              </div>
              <div className="px-3 big-card-heading d-flex position-relative">
                <h2 className="big-card-head w-100 text-cut-1 cursor-pointer" onClick={(event) => {
                  methods.openBlog(recentArticle.meta_keyword, recentArticle._id, recentArticle.catagory)
                }}>
                  {recentArticle?.title}
                </h2>
              </div>
              <p className="px-3 blogs-description-txt text-cut-3-real"
                dangerouslySetInnerHTML={{ __html: methods.formatDesc(recentArticle?.description) }}></p>
              <div className='px-3 pb-3'>
                <SocialBar id={recentArticle?._id} title={recentArticle?.meta_keyword} category={recentArticle?.catagory} />
              </div>
            </div>
          </div>
          <div className="trending-articles d-flex flex-column rg ">
            <h2 className="blogs-article-head">Trending Articles</h2>
            <div className="shadow-parent position-relative">
              <div className="position-absolute shadow-up"><span className="shadow-top position-sticky"></span></div>
              <div className="position-absolute shadow-down"><span className="shadow-bottom position-sticky"></span></div>
              {
                screen > 1200 ?
                  <div className="sm-card-wraper p-3 d-flex flex-column gap-4 position-relative">
                    {trendingArticles && trendingArticles.length &&
                      (trendingArticles?.map((blog, i) => {
                        return (
                          <div key={blog._id} className="blogs-side-card d-flex cg cursor-pointer"
                            onClick={(event) => {
                              methods.openBlog(blog.meta_keyword, blog._id, blog.catagory)
                            }}>
                            <div className="blogs-side-img">
                              <img  className="workout-blog3 w-100" ratio="289x193" lazysrc={imgURL + blog?.imagepath} alt={blog?.meta_keyword} />
                            </div>
                            <div className="blog-side-text d-flex flex-column">
                              <span className="big-card-date px-0">By: <b> {blog?.author} &#183;
                                {methods.formatDate(blog?.createdAt)}</b>
                              </span>
                              <h3 className="inner-sm-heading text-cut-3">{blog?.title}</h3>
                              <p className="px-0 inner-sm-description text-cut-3"
                                dangerouslySetInnerHTML={{ __html: methods.formatDesc(blog?.description) }}></p>
                              <div style={{ marginLeft: '-10px' }}>
                                <SocialBar id={blog?._id} title={blog?.meta_keyword} category={blog?.catagory} />
                              </div>
                            </div>

                          </div>
                        )
                      }))}
                  </div>
                  :
                  <div className="sm-card-wraper d-flex position-relative" style={{ height: 'auto' }}>
                    <ACarousel
                      data={
                        trendingArticles && trendingArticles.length &&
                        (trendingArticles?.map((blog, i) => {
                          return (
                            <div className="all-articles-card  d-flex flex-column rg" key={i} style={{ flexShrink: "0", width: '98%' }}>
                              <div className="blogs-random-card d-flex flex-column rg">
                                <img  className="random-card-img w-100" src={global_pointer.imgURL + blog.imagepath} alt={blog.meta_keyword} />
                                <span className="big-card-date">By: <b> {blog.author} &#183;
                                  {methods.formatDate(blog.createdAt)}</b>
                                </span>
                              </div>
                              <div className="big-card-heading d-flex position-relative">
                                <h2 className="inner-sm-heading w-75 cursor-pointer"
                                  onClick={(event) => { methods.openBlog(blog.meta_keyword, blog._id, blog.catagory) }}>{blog.title}</h2>
                              </div>
                              <p className="inner-sm-description text-cut-3" dangerouslySetInnerHTML={{ __html: methods.formatDesc(blog.description) }}></p>
                              <div className="blog-card-readm d-flex align-items-center cg cursor-pointer px-3"
                                onClick={(event) => { methods.openBlog(blog.meta_keyword, blog._id, blog.catagory) }}>
                                <p>Read More</p>
                                <img  className="blogs-card-arrow" src="/assets/blogs-arrow.png" alt='arrow icon'/>
                              </div>
                              <SocialBar id={blog?._id} title={blog?.meta_keyword} category={blog?.catagory} />
                            </div>
                          )
                        }))
                      }
                    />
                  </div>
              }
            </div>

          </div>
        </div>

        <div className="blogs-page-bottom mt-5rem">
          <h2 className="blogs-article-head">All Articles</h2>
          <div className="bottom-cards-wraper mt-5rem d-flex flex-row flex-wrap rg cg align-items-center justify-content-start">
            {
              currentPageData && currentPageData?.length &&
              currentPageData?.map(blog => {
                return (
                  <div key={blog._id} className="all-articles-card d-flex flex-column rg"
                  >
                    <div className="blogs-random-card d-flex flex-column rg">
                      <img  className="random-card-img w-100" ratio="404x240" lazysrc={imgURL + blog.imagepath} alt={blog.meta_keyword} />
                      <span className="big-card-date">By: <b> {blog.author} &#183;
                        {methods.formatDate(blog.createdAt)}</b>
                      </span>
                    </div>
                    <div className="big-card-heading d-flex position-relative">
                      <h2 className="inner-sm-heading text-cut-1 cursor-pointer"
                        onClick={(event) => {
                          methods.openBlog(blog.meta_keyword, blog._id, blog.catagory)
                        }}>
                        {blog.title}
                      </h2>
                    </div>
                    <p className="inner-sm-description text-cut-3"
                      dangerouslySetInnerHTML={{ __html: methods.formatDesc(blog.description) }}></p>
                    <SocialBar id={blog?._id} title={blog?.meta_keyword} category={blog?.catagory} />
                  </div>
                )
              })
            }
          </div>
        </div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={total && total}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </>
  )
}