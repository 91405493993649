import React, { useEffect, useState } from "react";
import "./fitness-workouts.css";
import MfctCard from "../components/MfctCard";
import global_pointer from "../components/Config";
import { useParams } from "react-router-dom";

export default function FitnessWorkouts() {
    const {id} = useParams();
    const [data, setData] = useState(null)
    const [category, setCategory] = useState("")
    const [name, setName] = useState("")
    const [error, setError] = useState("")

    const copyToClipboard = (e) => {
        let el = document.createElement('input');
        el.value = window.location.href;
        el.select();
        el.setSelectionRange(0, 99999); // For mobile devices
        window.navigator.clipboard.writeText(el.value);
        el.remove();

        e.target.classList.add('active');
        setTimeout(() => {
            e.target.classList.remove('active');
        }, 3000);
    }
    const fetchDetails = ()=> {
        fetch("https://myfitnesscoach.info/share/routine?id="+id)
        .then(y=>y.json())
        .then(response=>{
            setData(response.data);
            setCategory(response.routine_name);
            setName(response.name);
        })
        .catch(error=>{
            setError("No Data Found!")
        })

    }

    useEffect(fetchDetails, [])

    return (
        <>
            <div className="mfc-container">
                <header>
                    <div className="mfc-header">
                        <div className="mfc-left">
                            <img src="../../assets/nav-logo-sm.png" alt="myfitnesscoach logo"/>
                            <p>My Fitness Coach</p>
                        </div>
                        {/* <div className="mfc-right">
                            <button className="">Download</button>
                        </div> */}
                    </div>
                </header>
                <div className="mfc-body">
                    <div className="mfc-main-l">
                        {error?
                        <div className="d-flex align-items-center justify-content-center w-100 h-100">No Data Found!</div>
                        :
                        <>
                            <div className="mfc-title">{category}</div>
                            <div className="mfc-w-cards">
                                {
                                    data?.map(set=>{
                                        return (
                                            <>
                                                <div className="mfc-w-card">
                                                    <div className="mfcwc-title d-flex flex-column">
                                                        {
                                                            set.set_type != 'normal'?
                                                            <h4 className="mfc-wc-title">{set.set_type}</h4>
                                                            :
                                                            ''
                                                        }
                                                    <p className="mfc-wc-title">Perform the following exercises one after another.</p>
                                                    </div>
                                                    <div className="mfc-rows">
                                                        {
                                                            set.exercises.map(ex=>(
                                                                <>
                                                                    <MfctCard excercise={ex} />
                                                                    <span className="mfc-divider"></span>
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <span className="mfc-divider"></span>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </>}
                    </div>
                    <div className="mfc-main-r">
                        <div className="mfc-prof-card uper">
                            <div className="mfcp-top">
                                <span className="mfc-prof">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 15C21.3137 15 24 12.3137 24 9C24 5.68629 21.3137 3 18 3C14.6863 3 12 5.68629 12 9C12 12.3137 14.6863 15 18 15Z" stroke="#1B273E" stroke-width="2.25"/>
                                        <path d="M30 26.25C30 29.9775 30 33 18 33C6 33 6 29.9775 6 26.25C6 22.5225 11.373 19.5 18 19.5C24.627 19.5 30 22.5225 30 26.25Z" stroke="#1B273E" stroke-width="2.25"/>
                                    </svg>
                                </span>
                                <div className="mfc-credit">
                                    <p>Created By</p>
                                    <h4>{name}</h4>
                                </div>
                            </div>
                            <button className="copy-btn position-relative" onClick={copyToClipboard}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.7294 3.88438C17.1634 2.44438 19.2614 2.41438 20.4224 3.58038C21.5864 4.74838 21.5554 6.86038 20.1194 8.30038L17.6964 10.7334C17.56 10.875 17.4847 11.0646 17.4866 11.2612C17.4886 11.4579 17.5678 11.6459 17.707 11.7848C17.8463 11.9236 18.0345 12.0022 18.2312 12.0037C18.4278 12.0051 18.6172 11.9292 18.7584 11.7924L21.1824 9.35938C23.0934 7.44038 23.3334 4.37738 21.4854 2.52138C19.6354 0.664382 16.5784 0.906382 14.6654 2.82538L9.81944 7.69238C7.90844 9.61138 7.66844 12.6744 9.51644 14.5294C9.58546 14.6012 9.66808 14.6585 9.75949 14.698C9.85089 14.7375 9.94925 14.7584 10.0488 14.7595C10.1484 14.7606 10.2472 14.7418 10.3394 14.7043C10.4317 14.6668 10.5155 14.6114 10.5861 14.5411C10.6567 14.4709 10.7126 14.3873 10.7505 14.2952C10.7884 14.2031 10.8077 14.1044 10.807 14.0048C10.8064 13.9053 10.786 13.8068 10.7469 13.7152C10.7078 13.6236 10.6509 13.5407 10.5794 13.4714C9.41544 12.3034 9.44744 10.1914 10.8824 8.75138L15.7294 3.88438Z" fill="#4B596D"/>
                                    <path d="M14.4855 9.46962C14.3449 9.32866 14.1541 9.24931 13.955 9.24902C13.7559 9.24874 13.5649 9.32755 13.424 9.46812C13.283 9.60868 13.2036 9.79949 13.2034 9.99856C13.2031 10.1976 13.2819 10.3887 13.4225 10.5296C14.5865 11.6976 14.5555 13.8086 13.1195 15.2496L8.27246 20.1156C6.83746 21.5556 4.73946 21.5856 3.57846 20.4196C2.41446 19.2516 2.44646 17.1396 3.88146 15.6996L6.30546 13.2666C6.37499 13.1968 6.4301 13.114 6.46763 13.0229C6.50516 12.9318 6.52438 12.8342 6.5242 12.7357C6.52401 12.6372 6.50442 12.5397 6.46655 12.4487C6.42868 12.3578 6.37326 12.2752 6.30346 12.2056C6.23366 12.1361 6.15085 12.081 6.05976 12.0434C5.96866 12.0059 5.87107 11.9867 5.77254 11.9869C5.67402 11.9871 5.5765 12.0067 5.48555 12.0445C5.3946 12.0824 5.31199 12.1378 5.24246 12.2076L2.81846 14.6406C0.907459 16.5606 0.667459 19.6226 2.51546 21.4786C4.36546 23.3366 7.42246 23.0936 9.33546 21.1746L14.1825 16.3076C16.0935 14.3896 16.3335 11.3246 14.4855 9.46962Z" fill="#4B596D"/>
                                </svg>
                                Copy Routine Link
                            </button>
                        </div>
                        <div className="mfc-prof-card neechy">
                            <h4>Save Routine</h4>
                            <p>Download MyFitnessCoach now for free to save this routine.</p>
                            <div className="mfcp-bottom">
                            <a class=" d-flex flex-row align-items-center" href="https://play.google.com/store/apps/details?id=com.myfitnesscoach" target="_blank">
                                <img src="../assets/google-store-btn.svg" alt="Get it on Google Play Store"/>
                            </a>
                            <a class=" d-flex flex-row align-items-center" href="https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195" target="_blank">
                            <img src="../assets/app-store-btn.svg" alt="Get it on App store"/>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}