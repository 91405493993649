import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import NotFount from './components/NotFount';
import About from './pages/About';
import Blogs from './pages/Blogs';
import SingleBlog from './pages/SingleBlog';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import * as React from 'react';
import Faq from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Unsubscribe from './components/Unsubscribe';
import FitnessWorkouts from './pages/fitness-workouts';
import PrivacyTest from './pages/privacytest';
import FitnessReport from './pages/mobile_report_sharing/fitness-report';
import DelAccount from './pages/DelAccount';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

var observedImages = []
const AppLayout = () => (
  <>
    <Header />
    <main>
      <div className="container-xxl mt-5 pt-5 px-0 d-flex flex-column">
        <Outlet />
      </div>
    </main>
    <Footer />
  </>
);
const routesConfig = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'about-us',
        element: <About />,
      },
      {
        path: 'faq',
        element: <Faq />,
      },
      {
        path: 'blogs/all',
        element: <Blogs type="all" />,
      },
      {
        path: 'blogs/workout',
        element: <Blogs type="workout" />,
      },
      {
        path: 'blogs/recipe',
        element: <Blogs type="recipe" />,
      },
      {
        path: 'blogs/health',
        element: <Blogs type="health" />,
      },
      {
        path: 'blogs/:category/:title/:id',
        element: <SingleBlog />,
      },
      {
        path: 'blogs/:category/:title',
        element: <SingleBlog />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'terms-and-conditions',
        element: <Terms />,
      },
      {
        path: '/delete-app-account',
        element: <DelAccount />,
      },
      {
        path: 'test',
        element: <PrivacyTest />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFount />,
  },
  {
    path: '/unsubscribe/:id',
    element: <Unsubscribe />,
  },
  {
    path: '/routine/:id',
    element: <FitnessWorkouts />,
  },
  {
    path: '/getReport/:id',
    element: <FitnessReport />,
  },
];
const router = createBrowserRouter(routesConfig);
const App = () => {
  React.useEffect(() => {
    window.onload = () => {
      let links = document.querySelectorAll('a[href*=play\\.google\\.com]');
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        links.forEach((x) => {
          x.setAttribute(
            'href',
            'https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195'
          );
        });
      }
    };
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          const img = entry.target;
          let src = img.getAttribute('lazysrc');
          if (entry.isIntersecting) {
            let image = new Image();
            image.onload = () => {
              img.src = image.src; // Replace data-src with src
              img.setAttribute('style', ``);
              img.classList.remove('preloaded')
              img.removeAttribute('lazysrc'); // Remove attribute after loading
              observer.unobserve(img); // Stop observing once loaded
            };
            image.onerror = () => {
              img.src = image.src; // Replace data-src with src
              img.setAttribute('style', ``);
              img.classList.remove('preloaded')
              img.removeAttribute('lazysrc'); // Remove attribute after loading
              observer.unobserve(img); // Stop observing once loaded
            };
            image.src = src;
          }
        });
      },
      { rootMargin: '100px' } // Load slightly before entering viewport
    );

    const preFormatImage = (img) => {
      if (!img.getAttribute('lazysrc') || !img.getAttribute('ratio')) {
        return;
      }

      // img.src = '/assets/placeholder.webp'
      img.style.width = '100%';
      let ratio = img.getAttribute('ratio');
      ratio = ratio.split('x');
      ratio = parseInt(ratio[0]) / parseInt(ratio[1]);
      let width = Math.max(100, img.getBoundingClientRect().width);
      let height = Math.max(100, parseFloat(width) / parseFloat(ratio));
      // img.src = "/assets/lazy-img.jpg" // blur image
      img.classList.add('preloaded')
      img.setAttribute(
        'style',
        `width: ${width}px; height: ${height}px;`
      );

      observedImages.push(img)
      // console.log(observedImages, img);
      observer.observe(img)
    };

    const images = document.querySelectorAll('img[lazysrc][ratio]')
    images.forEach(img=>preFormatImage(img));

    // Use MutationObserver to detect new images dynamically
    const mutationObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === 'IMG' && !observedImages.includes(node)) {
              preFormatImage(node)
            } else if (node.nodeType === 1) {
              // If a new element is added, check if it contains <img> elements
              node.querySelectorAll('img').forEach((img) => {
                if(!observedImages.includes(img)) {
                  preFormatImage(img)
                }
              });
            }
          });
        }
      }
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return <RouterProvider router={router} />;
};
export default App;
