import React from 'react';
import { GlobalMethods } from '../../services/GlobalMethods';

export default function SocialBar(props) {
  const methods = new GlobalMethods();
  const {
    id,
    title,
    category
  } = props;
  return (
    <>
      <div className="big-card-bottom d-flex cg">
        <p className="blogs-share-sm">Share to:</p>
        <div className="d-flex social-wraper ps-3">
          <div className="cursor-pointer" onClick={
            (event) => {
              event.stopPropagation();
              methods.sharer(title, id, "facebook", category)
            }
          }>
            <img  className="" src="/assets/blogs-fb.svg" alt='fb icon'/>
          </div>
          <div className="cursor-pointer" onClick={
            (event) => {
              event.stopPropagation();
              methods.sharer(title, id, "twitter", category)
            }
          }>
            <img  className="" src="/assets/blogs-twitter.svg" alt='twiter icon'/>
          </div>
          <div className="cursor-pointer" onClick={
            (event) => {
              event.stopPropagation();
              methods.sharer(title, id, "pinterest", category)
            }
          }>
            <img  className="" src="/assets/blogs-printrest.svg" alt='printrest icon'/>
          </div>
          <div className="cursor-pointer" onClick={
            (event) => {
              event.stopPropagation();
              methods.sharer(title, id, "linkedin", category)
            }
          }>
            <img  className="" src="/assets/blogs-linkedin.svg" alt='linkedin icon'/>
          </div>
        </div>
      </div>
    </>
  )
}