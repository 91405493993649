import React, { useEffect, useState } from 'react';
import './Header.css';
import { NavLink, useLocation } from 'react-router-dom';
import { GlobalMethods } from '../services/GlobalMethods';
import Rocket from './utils/Rocket';
function Header() {
  const [current, setCurrent] = useState(null);
  const methods = new GlobalMethods();
  const pathname = useLocation().pathname.replace("/", "").split("/")[0]
  useEffect(() => {
    if (pathname != "" && pathname != "home") {
      setCurrent(GlobalMethods.current_sec)
    }
  }, [GlobalMethods.current_sec])
  useEffect(() => {
    if (GlobalMethods.current_sec == "error-page") return;
    window.addEventListener('scroll', (event) => {
      let offset = window.innerHeight / 2;
      let sections = event.target.querySelectorAll("#custom-scroll-spy > section");
      sections.forEach(x => {
        if (window.scrollY - offset > x.offsetTop + x.clientHeight) return;
        if (x.id == "info-videos-section") return;
        if (window.scrollY + offset >= x.offsetTop) {
          x.classList.add("animate-section");
        } else {
          if (window.scrollY <= x.offsetTop + offset) {
            x.classList.remove("animate-section");
            x.classList.add("leave-animation")
          } else {
            x.classList.remove("leave-animation")
          }
        }
        if (((window.scrollY + offset) >= (x.offsetTop))
          && ((window.scrollY) <= (x.offsetTop + x.clientHeight))) {
          setCurrent(x.id);
          x.setAttribute("data-active", true)
        } else {
          x.setAttribute("data-active", false)
        }
      })
    })
  })
  const handleNavClick = (event) => {
    if (window.innerWidth < 992) {
      var hamburger = document.querySelector("#navbar button.navbar-toggler");
      if (!hamburger.classList.contains("collapsed")) {
        hamburger.click();
      }
    }
  }
  return (
    <header>
      <div className="navs-container active" style={{ width: "100%" }}>
        <nav className="navbar navbar-expand-lg bg-light-primary" id="navbar">
          <div className="container-xxl">
            <NavLink className="navbar-brand d-flex align-items-center justify-content-center cg" to="/" data-section="hero-section"
              onClick={methods.handleNavigation}>
              <img  src="/assets/nav-logo-sm.png" className="nav-logo-sm" alt="MyFitnessCoach Logo" />
              <p className='main-logo-text'>MyFitnessCoach</p>
            </NavLink>
            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav" onClick={handleNavClick}>
              <div className="menu-wraper">
                <ul className="navbar-nav ms-auto cg">
                  <li className="nav-item">
                    <NavLink className={"nav-link menu-font" + (current == "hero-section" ? ' current' : '')} aria-current="page" to="/"
                      data-section="hero-section" onClick={methods.handleNavigation}>Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link menu-font" + (current == "app-working-section" ? ' current' : '')} to="/"
                      data-section="app-working-section" onClick={methods.handleNavigation}>How it works</NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink className={"nav-link menu-font" + (current == "diet-plans-section" ? ' current' : '')} to="/"
                      data-section="diet-plans-section" onClick={methods.handleNavigation}>Recipe</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link menu-font" + (current == "workout-goals-section" ? ' current' : '')} to="/"
                      data-section="workout-goals-section" onClick={methods.handleNavigation}>Workouts</NavLink>
                  </li>
                  <li className="nav-item">
                    <a className={"nav-link menu-font active" + (current == "faq-section" ? ' current' : '')} href="/faq"
                      data-section="faq-section" onClick={methods.handleNavigation}>FAQ's</a>
                  </li>
                  <li className="nav-item">
                    <a className={"nav-link menu-font active" + (current == "about-us-section" ? ' current' : '')} href="/about-us"
                      data-section="about-us-section" onClick={methods.handleNavigation}>About Us</a>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/blogs/all" className={"nav-link blogs-nav-item menu-font" + (current == "blogs-section" ? ' current' : '')}
                      data-section="blogs-section" onClick={methods.handleNavigation}
                      id="blogs-btn"
                    >Blogs
                    </NavLink>
                  </li>
                  <li className="nav-item ms-0 ms-lg-4 mt-2 mt-lg-0 download-buttonH">
                    <a className={"nav-link app-btn px-4 appLink"} target="_blank"
                      href={/iPhone|iPad|iPod/i.test(navigator.userAgent) ?
                        "https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195"
                        :
                        "https://play.google.com/store/apps/details?id=com.myfitnesscoach"}>
                      <span className='download-title'>Download</span>
                      &nbsp;
                      <span className='free-title'>it's free</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Rocket />
    </header>
  )
}

export default Header