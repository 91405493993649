import * as React from "react";
import '../utils/Carousel.css';
import ACarousel from "./AliceCarousel";

export default function Carousel(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const autoPlay = true;
  const responsive = {
    0: { items: 1 },    // For mobile devices
    600: { items: 2 },  // For tablets
    1024: { items: 3 }, // For desktops
  };
  return (
    props?.section == "diet-goals" ?
      (
        <ACarousel showDots hideNav items="1" data={[
          <div className='item c-diet-plan position-relative d-flex'>
            <div className='diet-body d-flex flex-column align-items-center rg overflow-hidden'>
              <img  className='recipies-grp' ratio="572x620" lazysrc='assets/recipies-grp.webp' alt="healthy recipes" />
              <h3 className='heading-64px '>Curated Diet Plans</h3>
              <p className='plan-txt-sm-black text-center'>Discover diet plans tailored to your specific needs and goals. We have designed these plans in consultation with a team of nutritionists and dietitians to create a well-balanced and nutritious approach to help you achieve the best health and fitness possible.</p>
            </div>
          </div>,
          <div className='item healthy-recipe position-relative d-flex'>
            <div className='recipe-body d-flex cg align-items-center'>
              <div className='recipy-left col-lg-6 col-md-12 d-flex flex-column rg align-items-center '>
                <img  className='healthy-grp' ratio="407x215" lazysrc='assets/healthy-recipe-grp.png' alt="healthy diet" />
                <h3 className='heading-64px'>Healthy Recipes for you to Explore</h3>
                <p className='plan-txt-sm-black text-start'>Wide range of recipes to make sure you get the most out of your diet plan. Our catalog contains <b>950+</b> recipes making sure you get the best results.</p>
              </div>
              <div className='recipy-right col-lg-6 col-md-12 '>
                <img  className='meal-grp' ratio="610x600" lazysrc='assets/meal-plan-grp.webp' alt="healthy meal" />
              </div>
            </div>
          </div>
        ]
        } />
      )
      :
      props?.section == "workout-goals" ?
        (
          <ACarousel showDots hideNav items="1" data={[
            <div className='item workout-slide1 d-flex align-items-center justify-content-center'>
              <div className='workout-body-wraper d-flex align-items-center '>
                <div className='workout-left col-lg-7 d-flex flex-column '>
                  <h3 className="heading-48px-white d-flex flex-row m-0">New & &nbsp;
                    <span className="text-logo d-flex flex-column position-relative">
                      <h3 className="heading-48px-white">Challenging </h3>
                      <img  src="assets/text-logo2.svg" alt="underline" className="challenge-dash w-auto position-absolute" />
                    </span>
                  </h3>
                  <h3 className="heading-48px-white">Workouts</h3>
                  <p className='plan-txt-white pt-4'>Take your fitness journey to the next level with a variety of exciting and challenging workouts. We offer a variety of innovative workout programs that are continuously updated, ensuring that your exercise routine remains fresh and enjoyable.</p>
                </div>
                <div className='workout-right col-lg-5 d-flex justify-content-end '>
                  <img  className='aaron-ag w-auto ' src='assets/workout-goals-grp.png' alt="workout goals" />
                </div>
              </div>
            </div>,
            <div className='item workout-slide2 d-flex align-items-center justify-content-center'>
              <div className='workout2-body-wraper d-flex align-items-center justify-content-center position-relative '>
                <div className='workout2-left col-lg-7 col-sm-12 '>
                  <h3 className='heading-48px-white'>Choose Workouts to Match Your Goals</h3>
                  <p className='plan-txt-white pt-4'>MyFitnessCoach can help you find workout routines that match your goals and fitness level. With simple exercises and tailored advice, you can reach your goals and stay motivated to start your journey today!</p>
                </div>
                <div className='workout2-right col-lg-5 col-sm-12 d-flex align-items-center justify-content-center'>
                  <div className='workout-img-wraper position-relative'>
                    <img  className='home-lifting w-100 ' src='assets/home-lifting-grp.png' alt="man lifting dumbbells" />
                  </div>
                </div>

              </div>
            </div>,
            <div className='item workout-slide3'>
              <div className='workout3-body-wraper d-flex align-items-baseline'>
                <div className='workout3-left col-lg-6 col-sm-12 pe-3 '>
                  <h3 className='hero-sub-heading'>Workouts Performed by Professionals</h3>
                  <p className='plan-txt black-light pt-4'>MyFitnessCoach offers a personalized approach to fitness, and provides access to certified professionals to help you get the most out of your workouts.</p>
                </div>
                <div className='workout3-right col-lg-6 col-sm-12 px-3 '>
                  <img  className='exercise-grp w-100 d-inline-block ' src='assets/exercise-grp.webp' alt="workouts" />
                </div>
              </div>
            </div>
          ]
          } />
        )
        :
        props?.section == "testimonial" ?
          (
            <ACarousel resposive={responsive}  swipeDelta={10}  data={[
              <div className='item testo-slide '>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
                </svg>
                <p className="testo-text ">As a beginner, I can for sure say this has tremendously helped with my fitness journey. A great way to keep myself in-check. MyFitnessCoach answers all my queries regarding anything fitness and diet related. 
                 <br/> <strong> Highly Recommended!</strong>
                </p>
                <p className="testo-aurthor ">Charlotte Davies</p>
                <div className="testo-footer">
                  <div className="store-icon">
                    <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.75 2.64789V33.351C0.750206 33.4176 0.770073 33.4827 0.807112 33.5381C0.84415 33.5934 0.89671 33.6366 0.958201 33.6622C1.01969 33.6879 1.08738 33.6948 1.15277 33.6821C1.21816 33.6694 1.27835 33.6376 1.32578 33.5909L17.3125 18.0002L1.32578 2.40805C1.27835 2.36128 1.21816 2.32956 1.15277 2.31686C1.08738 2.30416 1.01969 2.31105 0.958201 2.33666C0.89671 2.36228 0.84415 2.40548 0.807112 2.46084C0.770073 2.51621 0.750206 2.58128 0.75 2.64789ZM24.0156 11.594L3.97031 0.550233L3.95781 0.543202C3.6125 0.355702 3.28437 0.822889 3.56719 1.09476L19.2805 16.1198L24.0156 11.594ZM3.56875 34.9057C3.28437 35.1776 3.6125 35.6448 3.95937 35.4573L3.97187 35.4502L24.0156 24.4065L19.2805 19.8791L3.56875 34.9057ZM32.1078 16.0471L26.5102 12.9643L21.2469 18.0002L26.5102 23.0338L32.1078 19.9534C33.6305 19.112 33.6305 16.8885 32.1078 16.0471Z" fill="#1B2B4B"/>
                    </svg>
                  </div>
                  <div className="store-desc">
                    <div className="review-stars">
                      <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1071_5924)">
                        <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1071_5924">
                        <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1071_5924)">
                        <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1071_5924">
                        <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1071_5924)">
                        <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1071_5924">
                        <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1071_5924)">
                        <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1071_5924">
                        <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1071_5924)">
                        <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1071_5924">
                        <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <p className="store-name">Google Play Store</p>
                  </div>
                </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">I’ve been using this fitness app for a while now, and it has quickly become an essential part of my daily routine. The ap is incredibly user-friendly and offers a wide range of features that cater to all aspects of fitness and health. 
              </p>
              <p className="testo-aurthor ">Thomas Anderson
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.1836 32.5007C29.8002 34.5673 28.3336 36.584 26.1002 36.6173C23.8669 36.6673 23.1502 35.3007 20.6169 35.3007C18.0669 35.3007 17.2836 36.584 15.1669 36.6673C12.9836 36.7506 11.3336 34.4673 9.93357 32.4506C7.08357 28.334 4.90023 20.7507 7.83357 15.6507C9.28357 13.1173 11.8836 11.5173 14.7002 11.4673C16.8336 11.434 18.8669 12.9173 20.1836 12.9173C21.4836 12.9173 23.9502 11.134 26.5336 11.4007C27.6169 11.4507 30.6502 11.834 32.6002 14.7007C32.4502 14.8007 28.9836 16.834 29.0169 21.0507C29.0669 26.084 33.4336 27.7673 33.4836 27.784C33.4336 27.9007 32.7836 30.184 31.1836 32.5007ZM21.6669 5.83398C22.8836 4.45065 24.9002 3.40065 26.5669 3.33398C26.7836 5.28398 26.0002 7.25065 24.8336 8.65065C23.6836 10.0673 21.7836 11.1673 19.9169 11.0173C19.6669 9.10065 20.6002 7.10065 21.6669 5.83398Z" fill="#1B2B4B"/>
                  </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">App Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">Building muscle is one of the most important aspects of our lives. I salute you for this program.
              </p>
              <p className="testo-aurthor ">Alessandro Moretti
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.1836 32.5007C29.8002 34.5673 28.3336 36.584 26.1002 36.6173C23.8669 36.6673 23.1502 35.3007 20.6169 35.3007C18.0669 35.3007 17.2836 36.584 15.1669 36.6673C12.9836 36.7506 11.3336 34.4673 9.93357 32.4506C7.08357 28.334 4.90023 20.7507 7.83357 15.6507C9.28357 13.1173 11.8836 11.5173 14.7002 11.4673C16.8336 11.434 18.8669 12.9173 20.1836 12.9173C21.4836 12.9173 23.9502 11.134 26.5336 11.4007C27.6169 11.4507 30.6502 11.834 32.6002 14.7007C32.4502 14.8007 28.9836 16.834 29.0169 21.0507C29.0669 26.084 33.4336 27.7673 33.4836 27.784C33.4336 27.9007 32.7836 30.184 31.1836 32.5007ZM21.6669 5.83398C22.8836 4.45065 24.9002 3.40065 26.5669 3.33398C26.7836 5.28398 26.0002 7.25065 24.8336 8.65065C23.6836 10.0673 21.7836 11.1673 19.9169 11.0173C19.6669 9.10065 20.6002 7.10065 21.6669 5.83398Z" fill="#1B2B4B"/>
                </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">App Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">I think you can save money on gym subscription by going for this app. it so much easier to breakdown your workout schedule and you can even create a plan based on your needs.
                <br/> <strong>Amazing!</strong>
              </p>
              <p className="testo-aurthor ">Margit Kovács </p>
              <div className="testo-footer">
                <div className="store-icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.1836 32.5007C29.8002 34.5673 28.3336 36.584 26.1002 36.6173C23.8669 36.6673 23.1502 35.3007 20.6169 35.3007C18.0669 35.3007 17.2836 36.584 15.1669 36.6673C12.9836 36.7506 11.3336 34.4673 9.93357 32.4506C7.08357 28.334 4.90023 20.7507 7.83357 15.6507C9.28357 13.1173 11.8836 11.5173 14.7002 11.4673C16.8336 11.434 18.8669 12.9173 20.1836 12.9173C21.4836 12.9173 23.9502 11.134 26.5336 11.4007C27.6169 11.4507 30.6502 11.834 32.6002 14.7007C32.4502 14.8007 28.9836 16.834 29.0169 21.0507C29.0669 26.084 33.4336 27.7673 33.4836 27.784C33.4336 27.9007 32.7836 30.184 31.1836 32.5007ZM21.6669 5.83398C22.8836 4.45065 24.9002 3.40065 26.5669 3.33398C26.7836 5.28398 26.0002 7.25065 24.8336 8.65065C23.6836 10.0673 21.7836 11.1673 19.9169 11.0173C19.6669 9.10065 20.6002 7.10065 21.6669 5.83398Z" fill="#1B2B4B"/>
                </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">App Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">Offers a wide selection of healthy food options for people with specific dietary needs, and it also offers workout routines calibrated to your personal gym, and health goals. It is an easy way to keep track of weight goals and counting calories.
              </p>
              <p className="testo-aurthor ">Dimitri Petrov
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.1836 32.5007C29.8002 34.5673 28.3336 36.584 26.1002 36.6173C23.8669 36.6673 23.1502 35.3007 20.6169 35.3007C18.0669 35.3007 17.2836 36.584 15.1669 36.6673C12.9836 36.7506 11.3336 34.4673 9.93357 32.4506C7.08357 28.334 4.90023 20.7507 7.83357 15.6507C9.28357 13.1173 11.8836 11.5173 14.7002 11.4673C16.8336 11.434 18.8669 12.9173 20.1836 12.9173C21.4836 12.9173 23.9502 11.134 26.5336 11.4007C27.6169 11.4507 30.6502 11.834 32.6002 14.7007C32.4502 14.8007 28.9836 16.834 29.0169 21.0507C29.0669 26.084 33.4336 27.7673 33.4836 27.784C33.4336 27.9007 32.7836 30.184 31.1836 32.5007ZM21.6669 5.83398C22.8836 4.45065 24.9002 3.40065 26.5669 3.33398C26.7836 5.28398 26.0002 7.25065 24.8336 8.65065C23.6836 10.0673 21.7836 11.1673 19.9169 11.0173C19.6669 9.10065 20.6002 7.10065 21.6669 5.83398Z" fill="#1B2B4B"/>
                  </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">App Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">I was really worried about my diet and fitness, and I find the fitness coach while scrolling on Instagram. It's been a month and this app has some incredible features regarding diet and personalized workouts. It offers the veriety of diet which I love the most as I was so bored with the routine diet.<strong>I Highly Recommend this app.</strong>
              </p>
              <p className="testo-aurthor ">Henrik Olsen
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                  <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.75 2.64789V33.351C0.750206 33.4176 0.770073 33.4827 0.807112 33.5381C0.84415 33.5934 0.89671 33.6366 0.958201 33.6622C1.01969 33.6879 1.08738 33.6948 1.15277 33.6821C1.21816 33.6694 1.27835 33.6376 1.32578 33.5909L17.3125 18.0002L1.32578 2.40805C1.27835 2.36128 1.21816 2.32956 1.15277 2.31686C1.08738 2.30416 1.01969 2.31105 0.958201 2.33666C0.89671 2.36228 0.84415 2.40548 0.807112 2.46084C0.770073 2.51621 0.750206 2.58128 0.75 2.64789ZM24.0156 11.594L3.97031 0.550233L3.95781 0.543202C3.6125 0.355702 3.28437 0.822889 3.56719 1.09476L19.2805 16.1198L24.0156 11.594ZM3.56875 34.9057C3.28437 35.1776 3.6125 35.6448 3.95937 35.4573L3.97187 35.4502L24.0156 24.4065L19.2805 19.8791L3.56875 34.9057ZM32.1078 16.0471L26.5102 12.9643L21.2469 18.0002L26.5102 23.0338L32.1078 19.9534C33.6305 19.112 33.6305 16.8885 32.1078 16.0471Z" fill="#1B2B4B"/>
                  </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">Google Play Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">Surprised about how much information is being able to access for calories of food.
               <br/> <strong>I love it!</strong>
              </p>
              <p className="testo-aurthor ">Ethan Brooks
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                  <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.75 2.64789V33.351C0.750206 33.4176 0.770073 33.4827 0.807112 33.5381C0.84415 33.5934 0.89671 33.6366 0.958201 33.6622C1.01969 33.6879 1.08738 33.6948 1.15277 33.6821C1.21816 33.6694 1.27835 33.6376 1.32578 33.5909L17.3125 18.0002L1.32578 2.40805C1.27835 2.36128 1.21816 2.32956 1.15277 2.31686C1.08738 2.30416 1.01969 2.31105 0.958201 2.33666C0.89671 2.36228 0.84415 2.40548 0.807112 2.46084C0.770073 2.51621 0.750206 2.58128 0.75 2.64789ZM24.0156 11.594L3.97031 0.550233L3.95781 0.543202C3.6125 0.355702 3.28437 0.822889 3.56719 1.09476L19.2805 16.1198L24.0156 11.594ZM3.56875 34.9057C3.28437 35.1776 3.6125 35.6448 3.95937 35.4573L3.97187 35.4502L24.0156 24.4065L19.2805 19.8791L3.56875 34.9057ZM32.1078 16.0471L26.5102 12.9643L21.2469 18.0002L26.5102 23.0338L32.1078 19.9534C33.6305 19.112 33.6305 16.8885 32.1078 16.0471Z" fill="#1B2B4B"/>
                  </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">Google Play Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">The fitness app offers a diverse range of workouts catering to different fitness levels. Including a diverse range of healthy recipes aligned with fitness goals could greatly enhance the user experince, providing a practical and integrated approach to both workouts and nutrition.
              </p>
              <p className="testo-aurthor ">Henry Fletcher
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.1836 32.5007C29.8002 34.5673 28.3336 36.584 26.1002 36.6173C23.8669 36.6673 23.1502 35.3007 20.6169 35.3007C18.0669 35.3007 17.2836 36.584 15.1669 36.6673C12.9836 36.7506 11.3336 34.4673 9.93357 32.4506C7.08357 28.334 4.90023 20.7507 7.83357 15.6507C9.28357 13.1173 11.8836 11.5173 14.7002 11.4673C16.8336 11.434 18.8669 12.9173 20.1836 12.9173C21.4836 12.9173 23.9502 11.134 26.5336 11.4007C27.6169 11.4507 30.6502 11.834 32.6002 14.7007C32.4502 14.8007 28.9836 16.834 29.0169 21.0507C29.0669 26.084 33.4336 27.7673 33.4836 27.784C33.4336 27.9007 32.7836 30.184 31.1836 32.5007ZM21.6669 5.83398C22.8836 4.45065 24.9002 3.40065 26.5669 3.33398C26.7836 5.28398 26.0002 7.25065 24.8336 8.65065C23.6836 10.0673 21.7836 11.1673 19.9169 11.0173C19.6669 9.10065 20.6002 7.10065 21.6669 5.83398Z" fill="#1B2B4B"/>
                </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">App Store</p>
                </div>
              </div>
              </div>,
              <div className='item testo-slide '>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.1396 6.83559C8.49214 10.4626 5.35464 14.6843 4.72714 19.5008C3.74989 26.9986 10.4549 30.6683 13.8524 27.3713C17.2499 24.0743 15.2136 19.8886 12.7536 18.7441C10.2936 17.5996 8.78914 17.9986 9.05164 16.4693C9.31414 14.9401 12.8136 10.7018 15.8886 8.72784C15.9974 8.63508 16.0669 8.50436 16.0827 8.36225C16.0986 8.22015 16.0598 8.07733 15.9741 7.96284L15.2136 6.97359C14.8836 6.54459 14.5671 6.56109 14.1396 6.83484M29.0091 6.83559C23.3616 10.4626 20.2241 14.6843 19.5966 19.5008C18.6186 26.9986 25.3236 30.6683 28.7211 27.3713C32.1186 24.0743 30.0824 19.8886 27.6216 18.7441C25.1609 17.5996 23.6571 17.9986 23.9196 16.4693C24.1821 14.9401 27.6824 10.7018 30.7574 8.72784C30.8661 8.63497 30.9353 8.5042 30.9511 8.3621C30.9668 8.22 30.9279 8.07724 30.8421 7.96284L30.0824 6.97359C29.7524 6.54459 29.4359 6.56109 29.0091 6.83484" fill="#3941E9"/>
              </svg>
              <p className="testo-text ">MyFitnessCoach is easy to follow, and makes you realize how bad you have been eating and helps guide your meals.
               <br/> <strong>Recommended!</strong>
              </p>
              <p className="testo-aurthor ">Klara Novak
              </p>
              <div className="testo-footer">
                <div className="store-icon">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.1836 32.5007C29.8002 34.5673 28.3336 36.584 26.1002 36.6173C23.8669 36.6673 23.1502 35.3007 20.6169 35.3007C18.0669 35.3007 17.2836 36.584 15.1669 36.6673C12.9836 36.7506 11.3336 34.4673 9.93357 32.4506C7.08357 28.334 4.90023 20.7507 7.83357 15.6507C9.28357 13.1173 11.8836 11.5173 14.7002 11.4673C16.8336 11.434 18.8669 12.9173 20.1836 12.9173C21.4836 12.9173 23.9502 11.134 26.5336 11.4007C27.6169 11.4507 30.6502 11.834 32.6002 14.7007C32.4502 14.8007 28.9836 16.834 29.0169 21.0507C29.0669 26.084 33.4336 27.7673 33.4836 27.784C33.4336 27.9007 32.7836 30.184 31.1836 32.5007ZM21.6669 5.83398C22.8836 4.45065 24.9002 3.40065 26.5669 3.33398C26.7836 5.28398 26.0002 7.25065 24.8336 8.65065C23.6836 10.0673 21.7836 11.1673 19.9169 11.0173C19.6669 9.10065 20.6002 7.10065 21.6669 5.83398Z" fill="#1B2B4B"/>
                </svg>
                </div>
                <div className="store-desc">
                  <div className="review-stars">
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <svg className="fill-yellow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1071_5924)">
                      <path d="M3.32489 15.4226L4.56489 10.1096L0.441895 6.53764L5.87289 6.06764L7.99989 1.05664L10.1269 6.06664L15.5569 6.53664L11.4339 10.1086L12.6749 15.4216L7.99989 12.6016L3.32489 15.4226Z" fill="#CCD0E1"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1071_5924">
                      <rect width="16" height="15" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="store-name">App Store</p>
                </div>
              </div>
              </div>,
            ]
  
            } />
          )
          :
          ""
  );
}
